// The layout for the authentication section of the application. 

import React, { useRef, useEffect } from 'react';

// core components
import AuthNavbar from 'components/Navbars/AuthNavbar.js';
import SEO from 'components/seo.js';

const Auth = props => {
  const mainContent = useRef('mainContent');

  useEffect(() => {
    document.body.classList.add('bg-default');

    return () => { document.body.classList.remove('bg-default')  };
  });

  return (
    <>
      <SEO title="ERPFuze" />
      <div className="main-content" ref={mainContent}>
        <AuthNavbar />
        { props.children }
      </div>
    </>
  );
};

export default Auth;
