import React from 'react';

// reactstrap components
import { Container, Row, Col } from 'reactstrap';

class Login extends React.Component {
  render() {
    return (
      <>
        <div style={{
          position: 'fixed',
          width: '100%',
          bottom: '-4rem',
          left: '50%',
          transform: 'translateX(-50%)'
        }}>
          <Container>
            <Row className="d-flex align-items-center justify-content-center text-center">
              <Col xl="6">
                <div className="copyright text-center text-muted">
                  © {new Date().getFullYear()}{' '}
                  <a
                    rel="noreferrer"
                    className="font-weight-bold ml-1"
                    href="https://erpfuze.com"
                    target="_blank"
                  >
                    ERPFuze
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default Login;
